var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"727383a67ba8636558c5615ef6b637f49a14caf4"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from "@sentry/nextjs";

import {
  SENTRY_DSN,
  censorSensitiveInformation,
  ignoreErrors,
} from "./observability/sentryConfig";

Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://e80a6ecafad7417d96faf8386de4d6f2@o23399.ingest.sentry.io/4505128371224576",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.2,
  ignoreErrors,
  beforeSend(event) {
    censorSensitiveInformation(event);
    return event;
  },
});
